import { Box, Popover, Skeleton } from '@chakra-ui/react';
import { blueFilters, colorFilter, priceFilter, sizeFilter } from './_data';
import { useProductFilterState } from './useProductFilterState';
import { formatPrice } from '../../../domain/utils/PriceUtils';
import ProductSizePicker from './ProductSizePicker';
import ProductCheckboxFilter from './ProductCheckboxFilter';
import ProductColorPicker from './ProductColorPicker';
import { ProductFilterPopoverButton, ProductFilterPopoverContent } from './ProductFilterPopover';
import ProductPriceRangePicker from './ProductPriceRangePicker';
import ProductRadioFilter from './ProductRadioFilter';
import { IGetRewardsRequestParameters } from '../../../domain/models';
import { useApiManager } from '../../../domain/hooks';
import { useEffect, useState } from 'react';

export const SizeFilterPopover = () => {
	const state = useProductFilterState({
		defaultValue: '32',
		onSubmit: console.log,
	});

	return (
		<Popover placement="bottom-start" closeOnBlur>
			<ProductFilterPopoverButton label="Size" />
			<ProductFilterPopoverContent
				isCancelDisabled={!state.canCancel}
				onClickApply={() => {
					state.onSubmit();
				}}
				onClickCancel={() => {
					state.onReset();
				}}
			>
				<ProductSizePicker
					hideLabel
					value={state.value}
					onChange={state.onChange}
					options={sizeFilter.options}
				/>
			</ProductFilterPopoverContent>
		</Popover>
	);
};

export const PriceFilterPopover = () => {
	const state = useProductFilterState({
		defaultValue: priceFilter.defaultValue,
		onSubmit: console.log,
	});
	return (
		<Popover placement="bottom-start">
			<ProductFilterPopoverButton label="Price" />
			<ProductFilterPopoverContent
				isCancelDisabled={!state.canCancel}
				onClickApply={() => {
					state.onSubmit();
				}}
				onClickCancel={() => {
					state.onReset();
				}}
			>
				<Box px="2" pt="2">
					<ProductPriceRangePicker
						step={10}
						min={priceFilter.min}
						max={priceFilter.max}
						value={state.value}
						onChange={state.onChange}
					/>
					<Box as="output" mt="2" fontSize="sm">
						{state.value?.map((v) => formatPrice(v)).join(' — ')}
					</Box>
				</Box>
			</ProductFilterPopoverContent>
		</Popover>
	);
};

export const CheckboxFilterPopover = () => {
	const state = useProductFilterState({
		defaultValue: blueFilters.defaultValue,
		onSubmit: console.log,
	});
	return (
		<Popover placement="bottom-start">
			<ProductFilterPopoverButton label="Brand" />
			<ProductFilterPopoverContent
				isCancelDisabled={!state.canCancel}
				onClickApply={() => {
					state.onSubmit();
				}}
				onClickCancel={() => {
					state.onReset();
				}}
			>
				<ProductCheckboxFilter
					hideLabel
					value={state.value}
					onChange={(v: React.SetStateAction<string[] | undefined>) => {
						state.onChange(v);
					}}
					options={blueFilters.options}
				/>
			</ProductFilterPopoverContent>
		</Popover>
	);
};

export const ColorFilterPopover = () => {
	const state = useProductFilterState({
		defaultValue: colorFilter.defaultValue,
		onSubmit: console.log,
	});
	return (
		<Popover placement="bottom-start">
			<ProductFilterPopoverButton label="Color" />
			<ProductFilterPopoverContent
				isCancelDisabled={!state.canCancel}
				onClickApply={() => {
					state.onSubmit();
				}}
				onClickCancel={() => {
					state.onReset();
				}}
			>
				<ProductColorPicker
					hideLabel
					value={state.value}
					onChange={state.onChange}
					options={colorFilter.options}
				/>
			</ProductFilterPopoverContent>
		</Popover>
	);
};

interface ITagFilterProps {
	getRewardsParams: IGetRewardsRequestParameters;
	setGetRewardsParams: React.Dispatch<React.SetStateAction<IGetRewardsRequestParameters>>;
}
interface Option {
	label: string;
	value: string;
}

export const TagFilterPopover: React.FC<ITagFilterProps> = ({
	getRewardsParams,
	setGetRewardsParams,
}) => {
	const { useGetTags } = useApiManager();
	const { data, isLoading } = useGetTags();
	const [options, setOptions] = useState<Option[]>([]);
	const state = useProductFilterState({
		defaultValue: undefined,
		// onSubmit: console.log,
		onSubmit: (v: string | undefined) => {
			// console.log(v);
			setGetRewardsParams({
				...getRewardsParams,
				Tag: v,
			});
		},
	});
	// console.log(data);

	useEffect(() => {
		const newOpts: Option[] = [];
		data?.tags?.forEach((v) => {
			newOpts.push({ label: v.voucherTagName ?? 'UNKNOWN', value: v.voucherTagCode ?? 'NONE' });
		});
		setOptions(newOpts);
		// eslint-disable-next-line
	}, [data]);

	return (
		<Popover placement="bottom-start">
			<ProductFilterPopoverButton label="Category" />
			<ProductFilterPopoverContent
				// isCancelDisabled={!state.canCancel}
				onClickApply={() => {
					state.onSubmit();
				}}
				onClickCancel={() => {
					state.onReset();
				}}
				showReset
				onClickReset={() => {
					// console.log('Reset Clicked');
					setGetRewardsParams({
						...getRewardsParams,
						Tag: undefined,
					});
					// state.value = undefined;
					state.onReset();
				}}
			>
				<Skeleton isLoaded={!isLoading}>
					<ProductRadioFilter
						hideLabel
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						value={state.value}
						// onChange={(v: React.SetStateAction<string[] | undefined>) => {
						// 	state.onChange(v);
						// }}
						// onChange={(v) => {
						// 	console.log(v);
						// 	// state.onSubmit(v);
						// 	// state.onChange(v as React.SetStateAction<undefined>);
						// }}
						onChange={state.onChange}
						// options={blueFilters.options}
						options={options}
					/>
				</Skeleton>
			</ProductFilterPopoverContent>
		</Popover>
	);
};
