import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	CircularProgress,
	Flex,
	HStack,
	SimpleGrid,
	Text,
	useColorModeValue as mode,
} from '@chakra-ui/react';
import {
	// CheckboxFilterPopover,
	// ColorFilterPopover,
	// PriceFilterPopover,
	// SizeFilterPopover,
	TagFilterPopover,
} from './ProductFilter';
import ProductMobileFilter from './ProductMobileFilter';
import ProductGrid from './ProductGrid';
import ProductSortbySelect from './ProductSortbySelect';
import { IGetRewardsRequestParameters } from '../../../domain/models';
import { useApiManager } from '../../../domain/hooks';
import { useEffect, useState } from 'react';
import { Pagination } from '../pager/Pagination';
// import { useProductFilterState } from './useProductFilterState';

const ProductBlock: React.FC = () => {
	const [rewardsCurrentPage, setRewardsCurrentPage] = useState<number>(1);
	const [getRewardsParams, setGetRewardsParams] = useState<IGetRewardsRequestParameters>({
		PartnerCode: 'PG',
		IsActive: true,
		PageSize: 9,
		PageIndex: rewardsCurrentPage,
		SortBy: 'CreatedOn', //CreatedOn, Point, VoucherTypeName
		SortDirection: 'desc', //NOTE: Must be lower case. Currently Case-Sensitive.
	});
	const { useGetRewards } = useApiManager();
	const { data, isLoading, error } = useGetRewards(getRewardsParams);
	// const state = useProductFilterState({
	// 	defaultValue: undefined,
	// 	onSubmit: (v) => {
	// 		console.log(v);
	// 	},
	// });

	// FIlter Value Changed
	// useEffect(() => {
	// 	console.log('ProductBlock::FilterValue', value);
	// }, [value]);

	useEffect(() => {
		setGetRewardsParams({
			...getRewardsParams,
			PageIndex: rewardsCurrentPage,
		});
		// eslint-disable-next-line
	}, [rewardsCurrentPage]);

	return (
		<Box
			maxW="7xl"
			mx="auto"
			px={{ base: '4', md: '8', lg: '12' }}
			py={{ base: '6', md: '8', lg: '12' }}
		>
			{/* TODO Wishlist Tracker */}
			{/* <ProductBreadcrumb data={breadcrumbData} />
			<Heading size="lg" mt={{ base: '6', md: '10' }} mb="8">
				Men's Watches
			</Heading> */}

			<Flex justify="space-between" align="center" display={{ base: 'none', md: 'flex' }}>
				<HStack spacing="6">
					<Text color={mode('gray.600', 'gray.400')} fontWeight="medium" fontSize="sm">
						Filter by
					</Text>
					<SimpleGrid display="inline-grid" spacing="4" columns={4}>
						{/* <SizeFilterPopover /> */}
						{/* <PriceFilterPopover /> */}
						{/* <ColorFilterPopover />*/}
						{/* <CheckboxFilterPopover /> */}
						<TagFilterPopover
							getRewardsParams={getRewardsParams}
							setGetRewardsParams={setGetRewardsParams}
						/>
					</SimpleGrid>
				</HStack>

				<HStack flexShrink={0}>
					<Text
						as="label"
						htmlFor="sort-by"
						color={mode('gray.600', 'gray.400')}
						fontWeight="medium"
						fontSize="sm"
						whiteSpace="nowrap"
					>
						Sort by
					</Text>
					<ProductSortbySelect
						onChange={(e) => {
							// console.log(e.target.value);
							const newVals = e.target.value.split('-');
							// console.log(newVals);
							setGetRewardsParams({
								...getRewardsParams,
								SortBy: newVals[0],
								SortDirection: newVals[1],
							});
						}}
					/>
				</HStack>
			</Flex>

			<ProductMobileFilter />

			{isLoading && <CircularProgress isIndeterminate alignSelf={'center'} />}
			{error && (
				<Alert status="error">
					<AlertIcon />
					<Box>
						<AlertTitle>{error.name}</AlertTitle>
						<AlertDescription>{error.message}</AlertDescription>
					</Box>
				</Alert>
			)}

			<ProductGrid data={data} />
			<Pagination
				// siblingCount={2}
				isLoading={isLoading}
				props={{
					count: data?.pageInfo.count ?? 0,
					pageSize: data?.pageInfo.pageSize,
					page: rewardsCurrentPage,
					onPageChange: (e) => {
						// console.log('On Page Change::', e);
						setRewardsCurrentPage(e.page);
					},
				}}
			/>
		</Box>
	);
};
export default ProductBlock;
