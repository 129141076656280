import { Avatar, Button, HStack, Link, Skeleton, Text, VStack } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../data/globalstate';
import { IoDiamondOutline } from 'react-icons/io5';
import { useApiManager } from '../../../domain/hooks';
import { isNumber } from 'lodash';
import { TierTracker } from './TierTracker';

const UserBar: React.FC = () => {
	const { user, setUser, tiers } = useContext(AppContext);
	const navigate = useNavigate();
	const { useGetMemberBalance } = useApiManager();
	const { data: balanceData, isLoading: balanceLoading } = useGetMemberBalance({
		membershipCode: user?.burstId,
	});

	//Hook balance data to update app context to make balance available globally without additional calls.
	useEffect(() => {
		// console.log(balanceData);
		if (user && isNumber(balanceData?.balance.balance)) {
			user.balance = balanceData.balance.balance;
			// console.log('Update User', user);
			setUser(user);
		}
		// eslint-disable-next-line
	}, [balanceData, user]);

	if (!user) {
		return null;
	}

	return (
		<HStack
			bg={'#e7d5d1'}
			// h={'116px'}
			justifyContent={'space-between'}
			px={'20px'}
			spacing={'20px'}
			alignSelf={'stretch'}
		>
			<HStack>
				<Avatar />
				<VStack justifyContent={'flex-start'} alignItems={'flex-start'} gap={0}>
					<Text color={'black'}>
						Welcome Back, <span style={{ color: '#000' }}>{user.burstProfile.firstName}</span>
					</Text>
					<Link
						textTransform={'uppercase'}
						textDecoration={'underline'}
						color={'black'}
						onClick={() => {
							navigate('/profile');
						}}
					>
						view account
					</Link>
				</VStack>
				<Skeleton isLoaded={!balanceLoading}>
					<Button
						leftIcon={<IoDiamondOutline />}
						color={'white'}
						backgroundColor={'secondary.500'}
						ml={10}
						isDisabled
						_hover={{
							color: 'white',
							backgroundColor: 'secondary.500',
						}}
						_disabled={{
							color: 'white',
							backgroundColor: 'secondary.500',
						}}
					>
						Points Balance:&nbsp;
						<span style={{ color: '#e7d5d1' }}>
							{balanceData?.balance.balance.toLocaleString('en-US') ?? 0}
						</span>
					</Button>
				</Skeleton>
			</HStack>
			{tiers && tiers.length > 0 && (
				<TierTracker balanceLoading={balanceLoading} balanceData={balanceData} />
			)}
		</HStack>
	);
};
export default UserBar;
