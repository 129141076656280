import { Box, Divider, Flex, HStack, Img, Link, Text, VStack } from '@chakra-ui/react';
import { useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { AppContext } from '../../data/globalstate';
import { expireCookie } from '../../domain/utils/CookieUtils';

const footerLinks = {
	helpful: [
		{ text: 'Contact Us', url: '/contact-us' },
		{ text: 'FAQ', url: '#' },
		{ text: 'Site Map', url: '#' },
		{ text: 'Privacy Policy', url: '#' },
		{ text: 'Program Terms', url: '#' },
	],
	navigate: [
		{ text: 'Home', url: '/' },
		{ text: 'How It Works', url: '/how-it-works' },
		{ text: 'Redeem Rewards', url: '/rewards' },
		// { text: 'terms & conditions', url: '#' },
		// { text: 'customer support', url: '#' },
	],
};

const FooterThreeColumn: React.FC = () => {
	const { user, setUser } = useContext(AppContext);
	const navigate = useNavigate();
	return (
		<Flex
			as="section"
			minH="250px"
			position="relative"
			justifyContent={'flex-start'}
			minHeight={'500px'}
			// mt={'2rem'}
		>
			<VStack
				maxW={'1280px'}
				zIndex={1}
				position={'relative'}
				mx={'auto'}
				alignItems={'flex-start'}
				justifyContent={'flex-start'}
				flex={1}
			>
				<NavLink to="/">
					<Img
						src="https://burst-staging.ebbo.com/media/logo/stores/8/PinnacleOutdoor_header_logo_2x.png"
						alt="Logo"
						w={'134px'}
						objectFit={'contain'}
						mt={'2rem'}
						mb={'1rem'}
					/>
				</NavLink>
				<HStack gap={20} alignItems={'flex-start'}>
					<VStack maxW={'50%'} alignItems={'flex-start'}>
						<Text fontSize={'small'} textAlign={'left'} color={'black'}>
							&copy; Clarus Commerce LLC DBA ebbo. All rights reserved. CLARUS COMMERCE CONFIDENTIAL
							INFORMATION. All third-party trademarks and logos appearing on this site or the
							property of their respective owners and do not imply any affiliation with or
							endorsement by them. Examples are for FPO purposes. Final creative is subject to legal
							review.
						</Text>
						<Text fontSize={'small'} textAlign={'left'} color={'black'}>
							*NO PURCHASE NECESSARY. A purchase will not increase your chances of winning.
							Sweepstakes begins at 12:00 PM CT [PROMOTION START DATE] and ends at 11:59:59 PM CT
							[PROMOTION END DATE]. Open only to legal residents of [ELIGIBLE STATES] who are XX
							years or older. Click Here for Official Rules, including how to enter, free method of
							entry instructions, odds, prize details and restrictions. Void where prohibited.”
						</Text>
					</VStack>
					<Divider orientation="vertical" borderLeftWidth={2} borderColor={'gray.500'} />
					<VStack alignItems={'flex-start'} maxW={'20%'}>
						{/* <Text textTransform={'uppercase'} fontWeight={'bold'} mb={2}>
							Helpful Links
						</Text> */}
						{footerLinks.helpful.map((v, i) => (
							<Link
								as={NavLink}
								to={v.url}
								key={`link_c1_${i.toString()}`}
								// textTransform={'capitalize'}
								color="secondary.500"
							>
								{v.text}
							</Link>
						))}
					</VStack>
					<VStack alignItems={'flex-start'} maxW={'20%'}>
						{/* <Text textTransform={'uppercase'} fontWeight={'bold'} mb={2}>
							navigate
						</Text> */}
						{footerLinks.navigate.map((v, i) => (
							<Link
								as={NavLink}
								to={v.url}
								key={`link_c1_${i.toString()}`}
								// textTransform={'capitalize'}
								color="secondary.500"
							>
								{v.text}
							</Link>
						))}
						{/* Signout & View Account needs to be static */}
						{user && (
							<>
								<Link
									key={`viewaccount_link`}
									textTransform={'capitalize'}
									color="secondary.500"
									onClick={() => {
										navigate('/profile');
									}}
								>
									view account
								</Link>
								<Link
									key={`signout_link`}
									textTransform={'capitalize'}
									color="secondary.500"
									onClick={() => {
										// Logout
										user.cognitoUser.signOut();
										expireCookie('accessToken');
										setUser(undefined);
										navigate('/');
									}}
								>
									sign out
								</Link>
							</>
						)}
					</VStack>
				</HStack>
			</VStack>
			<Flex
				id="image-wrapper"
				position="absolute"
				insetX="0"
				insetY="0"
				w="full"
				h="full"
				overflow="hidden"
				align="center"
				bg={'white'}
			>
				<Box position="relative" w="full" h="full">
					<Img
						src={'/media/images/Footer_Img.webp'}
						alt="Footer Background"
						w="full"
						h="full"
						objectFit="cover"
						objectPosition="top bottom"
						position="absolute"
					/>
				</Box>
			</Flex>
		</Flex>
	);
};
export default FooterThreeColumn;
