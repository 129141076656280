import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	CircularProgress,
	Flex,
	Heading,
	Img,
	VStack,
} from '@chakra-ui/react';
import { IGetRewardsRequestParameters } from '../../../domain/models';
import { useApiManager } from '../../../domain/hooks';
import { useEffect, useState } from 'react';
import { Pagination } from '../pager/Pagination';
import ProductSpotlightGrid from './ProductSpotlightGrid';

const ProductSpotlightBlock: React.FC = () => {
	const [rewardsCurrentPage, setRewardsCurrentPage] = useState<number>(1);
	const [getRewardsParams, setGetRewardsParams] = useState<IGetRewardsRequestParameters>({
		PartnerCode: 'PG',
		IsActive: true,
		PageSize: 9,
		PageIndex: rewardsCurrentPage,
		SortBy: 'CreatedOn',
		SortDirection: 'desc',
	});
	const { useGetSpotlightRewards } = useApiManager();
	const { data, isLoading, error } = useGetSpotlightRewards(getRewardsParams);

	useEffect(() => {
		setGetRewardsParams({
			...getRewardsParams,
			PageIndex: rewardsCurrentPage,
		});
		// eslint-disable-next-line
	}, [rewardsCurrentPage]);

	return (
		<VStack bgColor={'primary.500'} mt={'calc(192px/2)'}>
			<Box
				maxW="7xl"
				mx="auto"
				px={{ base: '4', md: '8', lg: '12' }}
				py={{ base: '6', md: '8', lg: '12' }}
				pos={'relative'}
			>
				<Box
					position={'absolute'}
					top={0}
					left={'50%'}
					transform={'translate(-50%,-50%)'}
					zIndex={100}
				>
					<Flex
						bg={'secondary.500'}
						border={'2px solid white'}
						borderRadius={'50%'}
						aspectRatio={1}
						color={'white'}
						w={192}
						h={192}
					>
						<VStack gap={0} justifyContent={'flex-start'} alignItems={'center'}>
							<Img
								src="https://burst-staging.ebbo.com/media/logo/stores/8/PinnacleOutdoor_header_logo_2x.png"
								alt="Logo"
								w={'75%'}
								objectFit={'contain'}
								mt={'2rem'}
								mb={'1rem'}
								filter={'invert(1)'}
							/>
							<Heading as={'span'} size={'lg'} lineHeight={0}>
								SPOTLIGHT
							</Heading>
						</VStack>
					</Flex>
				</Box>
				{isLoading && <CircularProgress isIndeterminate alignSelf={'center'} />}
				{error && (
					<Alert status="error">
						<AlertIcon />
						<Box>
							<AlertTitle>{error.name}</AlertTitle>
							<AlertDescription>{error.message}</AlertDescription>
						</Box>
					</Alert>
				)}
				<ProductSpotlightGrid data={data} />
				<Pagination
					// siblingCount={2}
					isLoading={isLoading}
					props={{
						count: data?.pageInfo.count ?? 0,
						pageSize: data?.pageInfo.pageSize,
						page: rewardsCurrentPage,
						onPageChange: (e) => {
							// console.log('On Page Change::', e);
							setRewardsCurrentPage(e.page);
						},
					}}
					textColor="white"
				/>
			</Box>
		</VStack>
	);
};
export default ProductSpotlightBlock;
