import {
	Box,
	Button,
	Flex,
	FormLabel,
	HStack,
	Input,
	InputGroup,
	InputLeftAddon,
	InputRightAddon,
	Link,
	StackDivider,
	Text,
	useColorModeValue as mode,
	useBreakpointValue,
} from '@chakra-ui/react';
import { CartProductMeta } from './CartProductMeta';
import { ICartItem } from '../../../domain/models';
import { formatPrice, FormatPriceOptions } from '../../../domain/utils/PriceUtils';
import { Link as NavLink } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../../data/globalstate';

type CartItemProps = ICartItem &
	FormatPriceOptions & {
		onChangeQuantity?: (quantity: number) => void;
		onClickDelete?: () => void;
		onClickSaveForLater?: () => void;
	};

const QuantitySelect: React.FC<{
	id: string;
	quantity: number;
	onChangeQuantity?: (quantity: number) => void;
}> = (props) => {
	const { id, onChangeQuantity, quantity } = props;
	return (
		<Flex align="center">
			<FormLabel
				fontSize={{ base: 'sm', md: 'md' }}
				htmlFor={id}
				mb="0"
				color={mode('gray.600', 'gray.400')}
			>
				Qty
			</FormLabel>
			<InputGroup>
				<InputLeftAddon
					as={Button}
					onClick={() => {
						// console.log('decrease');
						onChangeQuantity?.(quantity - 1);
					}}
				>
					-
				</InputLeftAddon>
				<Input
					type="number"
					aria-label="Quantity"
					readOnly
					width="16"
					value={quantity}
					onChange={(e) => {
						onChangeQuantity?.(+e.currentTarget.value);
					}}
					textAlign={'center'}
					{...props}
				/>
				<InputRightAddon
					as={Button}
					onClick={() => {
						// console.log('increase');
						onChangeQuantity?.(quantity + 1);
					}}
				>
					+
				</InputRightAddon>
			</InputGroup>
		</Flex>
	);
};

const CartPageItem: React.FC<CartItemProps> = (props) => {
	const {
		// isBestSeller,
		// imageUrl,
		// isInStock,
		// name,
		// variants,
		onChangeQuantity,
		onClickDelete,
		// onClickSaveForLater,
		// price,
		locale,
		currency,
		quantity,
		// id,
		itemData,
	} = props;
	const { appConfiguration } = useContext(AppContext);
	const isMobile = useBreakpointValue({ base: true, md: false });
	let primaryImageUrl = itemData.imageURL;
	let primaryAltText = itemData.voucherName;

	if (!primaryImageUrl) {
		const match = itemData.imageAlternatives?.filter((v) => {
			return v.isPrimary;
		});
		if (match) {
			primaryImageUrl = match[0]?.url;
			primaryAltText = match[0]?.altText;
		}
	}

	return isMobile ? (
		<Box>
			<Flex>
				<NavLink to={itemData.rewardId ? `/product/${itemData.rewardId}` : '#'}>
					<CartProductMeta
						// isInStock={isInStock}
						title={itemData.voucherName ?? ''}
						// variants={variants}
						image={primaryImageUrl ?? appConfiguration?.placeHolderImage ?? undefined}
						// isBestSeller={isBestSeller}
						altText={primaryAltText}
					/>
				</NavLink>
				<Text pt="1" fontSize="sm" fontWeight="semibold">
					{formatPrice(itemData.points ?? 0, { locale, currency })}
				</Text>
			</Flex>
			<HStack mt="2" justify="space-between">
				<HStack
					mt="2"
					fontSize="sm"
					fontWeight="medium"
					divider={<StackDivider />}
					spacing="3"
					color={mode('blue.600', 'blue.300')}
				>
					<Link as="button" type="button" onClick={onClickDelete}>
						Delete
					</Link>
					{/* <Link as="button" type="button" onClick={onClickSaveForLater}>
						Save for later
					</Link> */}
				</HStack>
				{/* <QuantitySelect
					id={title}
					value={quantity}
					onChange={(e) => onChangeQuantity?.(+e.target.value)}
				/> */}
				<QuantitySelect
					id={itemData.rewardId ?? Math.random().toString()}
					quantity={quantity}
					onChangeQuantity={onChangeQuantity}
				/>
			</HStack>
		</Box>
	) : (
		<Flex align="flex-start" justify="space-between">
			<NavLink to={itemData.rewardId ? `/product/${itemData.rewardId}` : '#'}>
				<CartProductMeta
					// isInStock={isInStock}
					title={itemData.voucherName ?? ''}
					// variants={variants}
					image={primaryImageUrl ?? appConfiguration?.placeHolderImage ?? undefined}
					// isBestSeller={isBestSeller}
					altText={primaryAltText}
				/>
			</NavLink>
			<HStack width="full" maxW="sm" justify="space-between">
				<QuantitySelect
					id={itemData.rewardId ?? Math.random().toString()}
					quantity={quantity}
					onChangeQuantity={onChangeQuantity}
				/>
				<Flex
					direction="column"
					align="flex-end"
					justify="space-between"
					width="full"
					maxW="2xs"
					minH="16"
				>
					<Text fontWeight="semibold">
						{formatPrice(itemData.points ?? 0, { locale, currency })}
					</Text>
					<Flex direction="column" align="center">
						<HStack
							mt="2"
							fontSize="sm"
							fontWeight="medium"
							divider={<StackDivider />}
							spacing="3"
							color={mode('blue.600', 'blue.300')}
						>
							<Link as="button" type="button" fontWeight="medium" onClick={onClickDelete}>
								Delete
							</Link>
							{/* <Link as="button" type="button" fontWeight="medium" onClick={onClickSaveForLater}>
								Save for later
							</Link> */}
						</HStack>
					</Flex>
				</Flex>
			</HStack>
		</Flex>
	);
};
export default CartPageItem;
