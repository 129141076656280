import {
	FormControlProps,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Radio,
	RadioGroup,
	Stack,
	StackProps,
	UseRadioGroupProps,
	useColorModeValue as mode,
} from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';

interface Option {
	label: string;
	value: string;
}

interface RadioFilterProps extends UseRadioGroupProps {
	// hideLabel?: boolean;
	// options: { label: string; value: string; count?: number }[];
	// label?: string;
	// // onChange?: (value: string) => void;

	options: Option[];
	rootProps?: FormControlProps;
	hideLabel?: boolean;
	label?: string;
	spacing?: StackProps['spacing'];
	showSearch?: boolean;
}

const ProductRadioFilter: React.FC<RadioFilterProps> = (props) => {
	const { options, label, hideLabel, spacing = '2', showSearch, ...rest } = props;
	// const { getRadioProps, getRootProps, value } = useRadioGroup(rest);

	return (
		<Stack as="fieldset" spacing={spacing}>
			{!hideLabel && (
				<FormLabel fontWeight="semibold" as="legend" mb="0">
					{label}
				</FormLabel>
			)}
			{showSearch && (
				<InputGroup size="md" pb="1">
					<Input
						placeholder="Search..."
						rounded="md"
						focusBorderColor={mode('blue.500', 'blue.200')}
					/>
					<InputRightElement pointerEvents="none" color="gray.400" fontSize="lg">
						<FiSearch />
					</InputRightElement>
				</InputGroup>
			)}
			<RadioGroup {...rest}>
				<Stack>
					{options.map((option) => (
						<Radio key={option.value} value={option.value} colorScheme="blue">
							{/* <span>{option.label}</span>
						{option.count != null && (
							<Box as="span" color="gray.500" fontSize="sm">
								{' '}
								({option.count})
							</Box>
						)} */}
							{option.label}
						</Radio>
					))}
				</Stack>
			</RadioGroup>
		</Stack>
	);
};
export default ProductRadioFilter;
