/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Box, CircularProgress, Flex, Heading, Stack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import CartBar from './CartBar';
import NavigationBar from '../NavigationBar';
import { useCartManager } from '../../../domain/hooks';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import CartPageItem from './CartPageItem';
import CartPageOrderSummary from './CartPageOrderSummary';
import { PageTitle } from '../internal';
import FooterThreeColumn from '../FooterThreeColumn';

const CartPage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
	const { useGetCart, useRemoveCartItem, useChangeQuantity } = useCartManager();
	const { data: cartData, error: cartError } = useGetCart();
	const { mutateAsync: removeCartItem } = useRemoveCartItem();
	const { mutateAsync: changeQuantity } = useChangeQuantity();
	const isFetching = useIsFetching({ queryKey: ['cart'] });
	const isMutating = useIsMutating({ mutationKey: ['cart'] });
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (isFetching > 0 || isMutating > 0) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [isFetching, isMutating]);

	useEffect(() => {
		if (cartError) {
			console.error(cartError);
			// TODO Display
		}
	}, [cartError]);

	return (
		<Box textAlign="center" fontSize="xl" ref={ref}>
			{/* TODO Prefix Support? */}
			<PageTitle title="Cart" />
			<ScrollRestoration />
			<CartBar />
			<NavigationBar />
			<Box
				maxW="7xl"
				mx="auto"
				px={{ base: '4', md: '8', lg: '12' }}
				py={{ base: '6', md: '8', lg: '12' }}
			>
				<Stack spacing={{ base: '8', md: '12' }}>
					<Heading fontSize="2xl" fontWeight="extrabold">
						Shopping Cart ({cartData?.items?.length ?? '0'})
					</Heading>
					{loading && <CircularProgress isIndeterminate alignSelf={'center'} />}
					<Stack spacing="8">
						{cartData?.items?.map((item) => (
							<CartPageItem
								key={item.itemData.rewardId}
								{...item}
								locale="en-US"
								currency="POINTS"
								onChangeQuantity={(qty) => {
									// console.log('onChangeQuantity', qty);
									changeQuantity({ item: item, quantity: qty }).catch((e: unknown) => {
										// TODO Show
										console.log(e);
									});
								}}
								onClickDelete={() => {
									removeCartItem(item).catch((e: unknown) => {
										// TODO Show
										console.log(e);
									});
								}}
							/>
						))}
					</Stack>
					<Flex width="full" flexDirection="row" justifyContent="flex-end">
						<CartPageOrderSummary
							subtotal={cartData?.subtotal}
							shippingTax={0}
							numItems={cartData?.items.length}
						/>
					</Flex>
				</Stack>
			</Box>
			<FooterThreeColumn />
		</Box>
	);
});

export const AnimatedCartPage = motion(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <CartPage ref={ref} />),
);

export default CartPage;
