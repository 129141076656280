import React from 'react';
import { motion } from 'framer-motion';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { ScrollRestoration } from 'react-router-dom';
import CartBar from '../components/cart/CartBar';
import NavigationBar from '../components/NavigationBar';
import SidebarWhyJoin from '../components/contentBlocks/Sidebar_WhyJoin';
import TierBenefits from '../components/contentBlocks/TierBenefits';
import HowItWorksTrail from '../components/contentBlocks/HowItWorksTrail';
import FooterThreeColumn from '../components/FooterThreeColumn';
import { PageTitle } from '../components/internal';

// REVIEW Move this to a config variable?
// REVIEW Ensure this is for max, verify mobile is not impacted.
const contentWidth = '1280px';

const HowItWorksPage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => (
	<Box textAlign="center" fontSize="xl" ref={ref}>
		{/* TODO Prefix Support? */}
		<PageTitle title="How It Works" />
		{/* // direction={'column'} justifyContent={'center'}> */}
		{/* 100% page width */}
		<ScrollRestoration />
		<CartBar />
		<NavigationBar />
		<Flex
			id="backgrounddivBeige"
			direction={'column'}
			justifyContent={'flex-start'}
			alignItems={'center'}
			backgroundImage={'/media/images/HIW_background_img_beige.jpg'}
			backgroundPosition={'bottom left'}
			backgroundSize={'cover'}
			backgroundRepeat={'no-repeat'}
			width={'100%'}
		>
			{/* content width */}
			<Stack
				id="columns"
				direction={{ base: 'column', xl: 'row' }}
				spacing={{ base: '12', xl: '16' }}
				maxW={contentWidth}
				width={'100%'}
				alignSelf={'center'}
				mb={'30px'}
			>
				<SidebarWhyJoin />
				<HowItWorksTrail />
			</Stack>
			{/* end content width */}
		</Flex>
		<TierBenefits backgroundImageSrc="/media/images/chart-bg.webp" />
		<FooterThreeColumn />
	</Box>
));

export const AnimatedHowItWorksPage = motion(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <HowItWorksPage ref={ref} />),
);

export default HowItWorksPage;
