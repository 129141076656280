import { Box, Heading, Button, Flex, Img, Text, VStack } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import SignInButton from '../authentication/SignInButton';

interface IHeroImageBackground {
	title: string;
	text: string;
	backgroundImageSrc: string;
	joinButtonText: string;
	existText: string;
}

const HeroImageBackground: React.FC<IHeroImageBackground> = ({
	backgroundImageSrc,
	title,
	text,
	joinButtonText,
	existText,
}) => {
	return (
		<Box bg="white" as="section" minH="140px" position="relative">
			<Box py="32" position="relative" zIndex={1}>
				<Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '7', md: '8' }} color="white">
					<Box maxW="xl">
						<VStack justifyContent="flex-start" alignItems="flex-start">
							<Img
								src="https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/customer/Mountain_img.png"
								alt="Logo"
							/>
							<Heading
								as="h1"
								size="2xl"
								fontWeight="extrabold"
								textTransform="uppercase"
								textAlign="left"
								maxW="md"
							>
								{title}
							</Heading>
							<Text fontSize={{ md: '2xl' }} mt="4" maxW="sm" textAlign="left">
								{text}
							</Text>
							<Button
								as={NavLink}
								to={'/join'}
								colorScheme="gray"
								bg="white"
								color="black"
								variant="solid"
								px="8"
								rounded="5"
								size="lg"
								fontSize="md"
								textTransform="uppercase"
							>
								{joinButtonText}
							</Button>
							<Text fontSize={{ md: 'xl' }} maxW="lg" textTransform="uppercase">
								{existText}
							</Text>
							<SignInButton
								colorScheme="white"
								variant="outline"
								px="8"
								rounded="5"
								size="lg"
								fontSize="md"
								textTransform="uppercase"
							/>
						</VStack>
					</Box>
				</Box>
			</Box>
			<Flex
				id="image-wrapper"
				position="absolute"
				insetX="0"
				insetY="0"
				w="full"
				h="full"
				overflow="hidden"
				align="center"
			>
				<Box position="relative" w="full" h="full">
					<Img
						src={backgroundImageSrc}
						alt="Main Image"
						w="full"
						h="full"
						objectFit="cover"
						objectPosition="top bottom"
						position="absolute"
					/>
					<Box position="absolute" w="full" h="full" bg="blackAlpha.600" />
				</Box>
			</Flex>
		</Box>
	);
};
export default HeroImageBackground;
