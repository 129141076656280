import {
	Heading,
	VStack,
	Text,
	HStack,
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	ModalFooter,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Skeleton,
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../data/globalstate';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useApiManager } from '../../../domain/hooks';

const EditFormValidationSchema = yup.object({
	firstName: yup.string().required('First Name is required'),
	lastName: yup.string().required('Last Name is required'),
	// email: yup.string().email('Must be a valid email').required('Email is required'),
});

interface IEditForm {
	firstName?: string;
	lastName?: string;
	// email?: string;
}

const AccountDetails: React.FC = () => {
	const { user } = useContext(AppContext);
	const [showEdit, setShowEdit] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const { useGetMembership, useUpdateMembership } = useApiManager();
	const {
		data,
		isLoading,
		error: getError,
		refetch: refreshGet,
	} = useGetMembership({ membershipCode: user?.burstId ?? '' });
	const { mutateAsync, isPending, error: updateError, reset: resetUpdate } = useUpdateMembership();

	// Merge multiple possible loading triggers into one.
	useEffect(() => {
		if (isLoading || isPending) {
			setLoading(true);
		}
		if (!isLoading && !isPending) {
			setLoading(false);
		}
	}, [isLoading, isPending]);

	let birthdateString = 'Unknown';
	if (data?.member.birthdate) {
		const d = new Date(data.member.birthdate);
		if (d.getFullYear() > 1900) {
			birthdateString = d.toLocaleDateString('en-US');
		}
	}

	const formik = useFormik<IEditForm>({
		initialValues: {
			firstName: data?.member.firstName ?? '',
			lastName: data?.member.lastName ?? '',
			// email: data?.member.email ?? '',
		},
		validationSchema: EditFormValidationSchema,
		onSubmit: (values) => {
			console.log('Formik Submit::', values);
			// REVIEW - If email is different, should we update cognito?
			// FIXME - Email is immutable in burst.
			if (data && values.firstName && values.lastName) {
				//&& values.email
				setLoading(true);

				void mutateAsync({
					...data.member,
					id: data.member.id ?? null,
					promotionCode: null,
					firstName: values.firstName,
					lastName: values.lastName,
					// email: values.email,
					birthdate: data.member.birthdate ?? new Date('1900-01-01T00:00:00'),
				})
					.then(() => {
						setShowEdit(false);
						formik.resetForm();
						resetUpdate();
						void refreshGet();
					})
					.finally(() => {
						setLoading(false);
					});
			}
		},
	});

	//Ensure Formik Initial Values
	useEffect(() => {
		// console.log('Data Changed::', data);
		if (data) {
			//Reset Initial Values
			// formik.initialValues.email = data.member.email ?? '';
			formik.initialValues.firstName = data.member.firstName ?? '';
			formik.initialValues.lastName = data.member.lastName ?? '';
			//Set Current Values
			void formik.setValues(
				{
					// email: data.member.email ?? '',
					firstName: data.member.firstName ?? '',
					lastName: data.member.lastName ?? '',
				},
				false,
			);
		}
		// eslint-disable-next-line
	}, [data]);

	return (
		<VStack
			alignSelf={'stretch'}
			mt={'1rem'}
			backgroundColor={'white'}
			color={'secondary.500'}
			boxShadow={'0px 3px 6px #00000029'}
			alignItems={'flex-start'}
		>
			<Heading
				as="h2"
				textTransform="uppercase"
				backgroundColor={'primary.500'}
				color={'primaryText.500'}
				p="10px"
				alignSelf={'stretch'}
				fontSize={'x-large'}
			>
				ACCOUNT DETAILS
			</Heading>
			{getError && (
				<Alert status="error">
					<AlertIcon />
					<AlertTitle>Error retrieving your profile.</AlertTitle>
					<AlertDescription>{getError.message}</AlertDescription>
				</Alert>
			)}
			<HStack
				justifyContent={'flex-start'}
				alignSelf={'stretch'}
				p={'1rem'}
				alignItems={'flex-start'}
			>
				<VStack flex={1} alignItems={'flex-start'}>
					<Skeleton isLoaded={!loading}>
						<Text>First Name: {data?.member.firstName ?? 'Unknown'}</Text>
					</Skeleton>
					<Skeleton isLoaded={!loading}>
						<Text>Last Name: {data?.member.lastName ?? 'Unknown'}</Text>
					</Skeleton>
					<Skeleton isLoaded={!loading}>
						<Text>Email: {data?.member.email ?? 'Unknown'}</Text>
					</Skeleton>
				</VStack>
				<VStack flex={1} alignItems={'flex-start'}>
					<Skeleton isLoaded={!loading}>
						<Text>
							Join Date:{' '}
							{data?.member.joinDate
								? new Date(data.member.joinDate).toLocaleDateString('en-US')
								: new Date('12/31/1920').toLocaleDateString('en-US')}
						</Text>
					</Skeleton>
					<Skeleton isLoaded={!loading}>
						<Text>Birthday: {birthdateString}</Text>
					</Skeleton>
				</VStack>
			</HStack>
			<Skeleton isLoaded={!loading} p={'1rem'} pt={0}>
				<Button
					variant={'link'}
					textDecoration={'underline'}
					textTransform={'uppercase'}
					fontSize={'large'}
					colorScheme="primary"
					onClick={() => {
						setShowEdit(true);
					}}
				>
					Edit
				</Button>
			</Skeleton>
			<Modal
				isOpen={showEdit}
				onClose={() => {
					formik.resetForm();
					setShowEdit(false);
					resetUpdate();
				}}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign={'center'}>Edit Account Details</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<VStack gap={5}>
							{updateError && (
								<Alert status="error">
									<AlertIcon />
									<AlertTitle>Error updating your profile.</AlertTitle>
									<AlertDescription>{updateError.message}</AlertDescription>
								</Alert>
							)}
							<FormControl isRequired isInvalid={Boolean(formik.errors.firstName)}>
								<FormLabel htmlFor="firstName">First Name</FormLabel>
								<Input
									id="firstName"
									type="text"
									name="firstName"
									required
									value={formik.values.firstName}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									disabled={loading}
									isDisabled={loading}
								/>
								{formik.errors.firstName && (
									<FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
								)}
							</FormControl>
							<FormControl isRequired isInvalid={Boolean(formik.errors.lastName)}>
								<FormLabel htmlFor="lastName">Last Name</FormLabel>
								<Input
									id="lastName"
									type="text"
									name="lastName"
									required
									value={formik.values.lastName}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									disabled={loading}
									isDisabled={loading}
								/>
								{formik.errors.lastName && (
									<FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
								)}
							</FormControl>
							{/* <FormControl isRequired isInvalid={Boolean(formik.errors.email)}>
								<FormLabel htmlFor="email">Email</FormLabel>
								<Input
									id="email"
									type="email"
									name="email"
									required
									value={formik.values.email}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									disabled={loading}
									isDisabled={loading}
								/>
								{formik.errors.email && <FormErrorMessage>{formik.errors.email}</FormErrorMessage>}
							</FormControl> */}
						</VStack>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="primary"
							isLoading={loading}
							onClick={() => {
								void formik.submitForm();
							}}
						>
							Update
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</VStack>
	);
};

export default AccountDetails;
