import { useContext, useEffect } from 'react';
import { AppContext } from '../../../data/globalstate';
import { useApiManager } from '../../../domain/hooks';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
	VStack,
	Spinner,
	Heading,
	Stack,
	FormControl,
	FormLabel,
	Button,
	Text,
	Select,
	CheckboxGroup,
	Checkbox,
	FormErrorMessage,
} from '@chakra-ui/react';
import React from 'react';
import { EPromotionType, IClaimPromotionRequest } from '../../../domain/models';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { clone } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';

// TODO Refactor
interface IEarningAction {
	id: string;
	type: EPromotionType;
	action?: string;
	points: number;
	title: string;
	description: string;
	actionText?: string;
}

interface IProps {
	showModal: boolean;
	setShowModal: React.Dispatch<boolean>;
	earningAction?: IEarningAction;
	setSelectedAction: React.Dispatch<IEarningAction | undefined>;
}

const questions = [
	{
		name: 'What is your favorite outdoor athletic activity?',
		type: 'single',
		answers: [
			{ name: 'Hiking/Walking', value: '1' },
			{ name: 'Climbing/Caving', value: '2' },
			{ name: 'Golfing', value: '3' },
			{ name: 'Softball/Baseball', value: '4' },
			{ name: 'Soccer/Football', value: '5' },
			{ name: 'Cloud watching', value: '6' },
		],
	},
	{
		name: 'Besides Pinnacle, which other retailers do you purchase from? Select all that apply.',
		type: 'multi',
		answers: [
			{ name: 'Academy Sports', value: 'Academy Sports' },
			{ name: 'REI', value: 'REI' },
			{ name: 'Dick’s Sporting Goods', value: 'Dick’s Sporting Goods' },
			{ name: 'Cabela’s', value: 'Cabela’s' },
			{ name: 'Dunham’s Sports', value: 'Dunham’s Sports' },
			{ name: 'Amazon', value: 'Amazon' },
			{ name: 'Mother Nature', value: 'Mother Nature' },
			{ name: 'Other', value: 'Other' },
		],
	},
	{
		name: 'How many times a year do you engage in your outdoor athletic activities?',
		type: 'single',
		answers: [
			{ name: '1-5', value: '1-5' },
			{ name: '6-10', value: '6-10' },
			{ name: '10-20', value: '10-20' },
			{ name: '20-50', value: '20-50' },
			{ name: '50+', value: '50+' },
			{ name: 'Why would I ever leave my house?!?', value: 'never' },
		],
	},
	{
		name: 'How do you typically experience your outdoor athletic activities?',
		type: 'single',
		answers: [
			{ name: 'With a small group of 2-10', value: 'small' },
			{ name: 'With a larger group of 10+', value: 'large' },
			{ name: 'As a team', value: 'team' },
			{ name: 'With my family', value: 'family' },
			{ name: 'It depends on the activity', value: 'depends' },
			{ name: 'It’s just me, myself, and I', value: 'solo' },
		],
	},
];

// TODO Form Validation.
const ValidationScheme = yup.object({
	q1: yup.string().required('This question is required.'),
	q2: yup.array().min(1, 'This question is required.'),
	q3: yup.string().required('This question is required.'),
	q4: yup.string().required('This question is required.'),
});

interface testQs {
	q1?: string;
	q2?: string[];
	q3?: string;
	q4?: string;
}

export const ProfileModal: React.FC<IProps> = ({
	showModal,
	setShowModal,
	earningAction,
	setSelectedAction,
}) => {
	const { user } = useContext(AppContext);
	const { useClaimEarningAction } = useApiManager();
	const { mutateAsync, error, isSuccess, isPending, reset } = useClaimEarningAction();
	const qc = useQueryClient();
	// TODO Get Profile Question Details
	// const [value, setValue] = React.useState('1');
	const formik = useFormik<testQs>({
		initialValues: {
			q1: '',
			q2: [],
			q3: '',
			q4: '',
		},
		validationSchema: ValidationScheme,
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: () => {
			// console.log(values);
			const ea: IClaimPromotionRequest = {
				transactionDescription: earningAction?.title ?? 'Unknown Earning Action',
				membershipCode: user?.burstId ?? null,
				activityType: EPromotionType.Profile,
				activityKey: earningAction?.id ?? '',
				partnerCode: 'PG',
				transactionProperties: null,
				itemProperties: null,
			};
			// console.log('Claim Action', ea);
			mutateAsync(ea)
				.then(() => {
					// console.log('New Transaction Success :: ', v);
					// setShowModal(false);
					void qc.invalidateQueries({ queryKey: ['GetAction', earningAction?.id] });
					// reset();
				})
				.catch((e: unknown) => {
					console.error(e);
					// TODO
				});
		},
	});

	//Reset Video Watched tracking.
	useEffect(() => {
		if (!showModal) {
			reset();
			setSelectedAction(undefined);
		}
		// eslint-disable-next-line
	}, [showModal]);

	return (
		<Modal
			size={'xl'}
			isOpen={showModal}
			onClose={() => {
				// KEEP OPEN
				// setShowModal(false);
			}}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader textAlign={'center'} textTransform={'uppercase'}>
					{earningAction?.title}
				</ModalHeader>
				<ModalCloseButton
					onClick={() => {
						setShowModal(false);
						formik.resetForm();
					}}
				/>
				<ModalBody>
					{error && (
						<Alert
							status="error"
							flexDirection="column"
							alignItems="center"
							justifyContent="center"
							textAlign="center"
						>
							<AlertIcon />
							<AlertTitle>We encountered an error awarding your points.</AlertTitle>
							<AlertDescription>{error.message}</AlertDescription>
						</Alert>
					)}
					{isSuccess && (
						<VStack>
							<Alert
								status="success"
								flexDirection="column"
								alignItems="center"
								justifyContent="center"
								textAlign="center"
							>
								<AlertIcon />
								<AlertTitle>You've earned {earningAction?.points} points!</AlertTitle>
								<AlertDescription>You may now close this window.</AlertDescription>
							</Alert>
							<Text>Completed on {new Date().toLocaleDateString()}</Text>
						</VStack>
					)}
					{isPending && (
						<VStack>
							<Spinner size="xl" color={'primary.500'} />
							<Heading>Claiming Points...</Heading>
						</VStack>
					)}
					{!isPending && !isSuccess && (
						<VStack>
							{questions.map((v, idx) => {
								if (v.type === 'single') {
									return (
										<FormControl
											key={`field_${idx.toString()}`}
											isInvalid={
												(idx + 1 === 1 && Boolean(formik.errors.q1)) ||
												(idx + 1 === 3 && Boolean(formik.errors.q3)) ||
												(idx + 1 === 4 && Boolean(formik.errors.q4))
											}
										>
											<FormLabel>{v.name}</FormLabel>
											<Select
												id={`q${(idx + 1).toString()}`}
												name={`q${(idx + 1).toString()}`}
												placeholder="Select option"
												size={'md'}
												variant={'outline'}
												onBlur={formik.handleBlur}
												onChange={formik.handleChange}
											>
												{v.answers.map((a, i) => {
													return (
														<option
															key={`choice_${idx.toString()}_${i.toString()}`}
															value={a.value}
														>
															{a.name}
														</option>
													);
												})}
											</Select>
											{idx + 1 === 1 && formik.errors.q1 && (
												<FormErrorMessage>{formik.errors.q1}</FormErrorMessage>
											)}
											{idx + 1 === 3 && formik.errors.q3 && (
												<FormErrorMessage>{formik.errors.q3}</FormErrorMessage>
											)}
											{idx + 1 === 4 && formik.errors.q4 && (
												<FormErrorMessage>{formik.errors.q4}</FormErrorMessage>
											)}
										</FormControl>
									);
								} else {
									return (
										<FormControl
											key={`field_${idx.toString()}`}
											isInvalid={Boolean(formik.errors.q2)}
										>
											<FormLabel>{v.name}</FormLabel>
											<CheckboxGroup colorScheme="primary" defaultValue={formik.values.q2}>
												<Stack spacing={[1, 5]} direction={['column', 'row']} flexWrap={'wrap'}>
													{v.answers.map((a, i) => {
														return (
															<Checkbox
																key={`choice_${idx.toString()}_${i.toString()}`}
																value={a.value}
																onBlur={formik.handleBlur}
																onChange={(e) => {
																	// console.log('event', e);
																	let newVal = clone(formik.values.q2 ?? []);
																	// console.log('newVal', newVal);
																	const found = newVal.find((v) => v === e.target.value);
																	// console.log('found', found);
																	// console.log('selected', e.target.value);
																	if (!found && e.target.checked) {
																		newVal.push(e.target.value);
																	} else {
																		if (found && !e.target.checked) {
																			newVal = newVal.filter((v) => v !== e.target.value);
																		}
																	}
																	void formik.setFieldValue('q2', newVal);
																}}
															>
																{a.name}
															</Checkbox>
														);
													})}
												</Stack>
											</CheckboxGroup>
											{formik.errors.q2 && <FormErrorMessage>{formik.errors.q2}</FormErrorMessage>}
										</FormControl>
									);
								}
							})}
							<Button
								colorScheme="primary"
								variant={'outline'}
								onClick={() => {
									formik.handleSubmit();
								}}
							>
								Submit
							</Button>
						</VStack>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
