import { useQuery } from '@tanstack/react-query';
import { gql, request } from 'graphql-request';
import { AppContext, IAppConfiguration } from '../../data/globalstate';
import { useContext } from 'react';
import { clone } from 'lodash';

interface ISettingsResponse {
	general: {
		cognitoClient: string;
		cognitoUser: string;
		id: string;
		placeHolderImageUrl: string;
		primaryColor: {
			size100: {
				hex: string;
			};
			size200: {
				hex: string;
			};
			size300: {
				hex: string;
			};
			size400: {
				hex: string;
			};
			size50: {
				hex: string;
			};
			size500: {
				hex: string;
			};
			size600: {
				hex: string;
			};
			size700: {
				hex: string;
			};
			size800: {
				hex: '#030806';
			};
			size900: {
				hex: string;
			};
		};
		placeHolderImageAsset: null;
		useLoginPage: boolean;
		useImageUpload: boolean;
		useCartPage: boolean;
		primaryTextColor: {
			size100: {
				hex: string;
			};
			size200: {
				hex: string;
			};
			size300: {
				hex: string;
			};
			size400: {
				hex: string;
			};
			size50: {
				hex: string;
			};
			size500: {
				hex: string;
			};
			size600: {
				hex: string;
			};
			size700: {
				hex: string;
			};
			size800: {
				hex: string;
			};
			size900: {
				hex: string;
			};
		};
		secondaryColor: {
			size100: {
				hex: string;
			};
			id: string;
			size200: {
				hex: string;
			};
			size300: {
				hex: string;
			};
			size400: {
				hex: string;
			};
			size50: {
				hex: string;
			};
			size500: {
				hex: string;
			};
			size600: {
				hex: string;
			};
			size700: {
				hex: string;
			};
			size800: {
				hex: string;
			};
			size900: {
				hex: string;
			};
		};
		secondaryTextColor: {
			size100: {
				hex: string;
			};
			size200: {
				hex: string;
			};
			size300: {
				hex: string;
			};
			size400: {
				hex: string;
			};
			size50: {
				hex: string;
			};
			size500: {
				hex: string;
			};
			size600: {
				hex: string;
			};
			size700: {
				hex: string;
			};
			size800: {
				hex: string;
			};
			size900: {
				hex: string;
			};
		};
	};
}

const accessToken =
	'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE3Mjk2NjYyODcsImF1ZCI6WyJodHRwczovL2FwaS11cy13ZXN0LTIuaHlncmFwaC5jb20vdjIvY20ybDYxMHQ4MDFhZjA3dXR6MWNxZDl2dS9tYXN0ZXIiLCJtYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC11cy13ZXN0LTIuaHlncmFwaC5jb20vIiwic3ViIjoiNjgxMTZiODYtMjc3Yi00ZWQ5LTlkYTItMzUzMGUyNTJjZDFhIiwianRpIjoiY20ybGluZGF2MGNpNDA2bjFoMHRyZ3N1diJ9.ePwunFCoBCh-RH-xrx0A4aNujYzHhqHZgpRpWzcOtQ9i-tp-s00x3QTMnroamBsOvDv4VCXqgied2_DMYD0M70TkVpVFQ8O7m3CZF0BrJ1lUBD_LVIh0o6Km4_a3OOf7Svlh4YL1z5BVtzs7lkdsJ6m4thcrME3bMM7k6e04xTzTb6neqkZIxtnZFvmqibH9kmZR8tfllqbsJEduEqrf8u-Ln_H7sVOdyovYHHcpACFzf-AtL5voMOnkQvY9RiYd7F1wY8uUqLL3_8qWM3xg6RjJEMamovLvoAOZ56KY3POwk7R0xr6Ka7VNWk4_cbPuOkZaMPruxXhF794qcfq4V54gU_zVBW61BSICyNnuqBIHTd9r9-jZJy6TsqKvRut9Vyi0AwDtn6kf6JTJy3w_3oLStv8y0BYhAQCmDhJ6t7z549FR5yfkKp5HcLQ-6klbz-7ORkcoGeNBM4IoiJzSyBybqsK9b5R7XlZ3VD28uSlXn5CuJ20ETTxDdaH2V6vYXiqxQiNFTnsoOHocMysgxWpxNCnwC0jjF0-MPZQNDDd-IiSo656EpBhJw70T9VGFxVaiZmtY6B0scSd5dME2ikQPQPIPoLcVfM6TIPmHedFMVWOUn78EroLYtvkmsAkCtQOsRRrMAVNB4TjA_ukoqfkWTYiHb6r10b-ePTw6Yvs';
const url = 'https://us-west-2.cdn.hygraph.com/content/cm2l610t801af07utz1cqd9vu/master';

export const useHygraph = () => {
	return {
		useGetHomePage: () => {
			const { appConfiguration, setAppConfiguration } = useContext(AppContext);
			return useQuery({
				queryKey: ['getHomePage'],
				queryFn: () => {
					// console.log('useHygraph::useGetHomePage');
					return new Promise((resolve, reject) => {
						// eslint-disable-next-line @typescript-eslint/no-unsafe-call
						request(
							url,
							gql`
								query {
									page(stage: PUBLISHED, where: { id: "cm2lhgaj99pkv07n4f6k3e1ns" }) {
										title
										content {
											... on TextField {
												id
												text
												title
											}
											... on Image {
												id
												description
												imageUrl
												title
												uploadImage
											}
											... on ColorScheme {
												id
												size100 {
													hex
												}
												size200 {
													hex
												}
												size300 {
													hex
												}
												size400 {
													hex
												}
												size50 {
													hex
												}
												size500 {
													hex
												}
												size600 {
													hex
												}
												size700 {
													hex
												}
												size800 {
													hex
												}
												size900 {
													hex
												}
											}
										}
										id
									}
								}
							`,
							undefined,
							{ Authorization: `Bearer ${accessToken}` },
						)
							// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
							.then((d) => {
								// console.log(d);
								// console.log(appConfiguration);
								const newAppConfig = clone(appConfiguration);
								if (newAppConfig) {
									// @ts-expect-error Unmapped Type
									// eslint-disable-next-line
									const title = d.page.content.find((v) => v.title === 'Page Title');
									// @ts-expect-error Unmapped Type
									// eslint-disable-next-line
									const JoinButtonText = d.page.content.find((v) => v.title === 'JoinButtonText');
									// @ts-expect-error Unmapped Type
									// eslint-disable-next-line
									const bgImage = d.page.content.find((v) => v.title === 'backgroundImageSrc');
									newAppConfig.content = {
										HomePage: {
											HeroImageBackground: {
												// eslint-disable-next-line
												title: title.text ?? 'Welcome to Peak Rewards',
												text: 'Register for free and start earning points you can use on your next adventure.',
												// eslint-disable-next-line
												backgroundImageSrc:
													// eslint-disable-next-line
													bgImage.imageUrl ?? '/media/images/HeaderImg_withgreen_2x.webp',
												// eslint-disable-next-line
												joinButtonText: JoinButtonText.text ?? 'Join Now',
												existText: 'Already a peak rewards member?',
											},
											PageHeaderCenteredOnAccent: {
												logo: 'https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/no_member/Mountain_img_blue.png',
												heading: 'epic adventures deserve epic rewards',
												text: 'Gear up for your next adventure with great rewards, starting now.',
											},
											PerksThreeColumn: {},
											PageHeaderCentered: {},
											TierBenefits: {},
										},
									};
									setAppConfiguration(newAppConfig);
								}
								resolve('Success');
							})
							// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
							.catch((e: unknown) => {
								console.error(e);
								reject(e);
							});
					});
				},
			});
		},
		// () => {
		// {

		//   }
		// 	return null;
		// },
		useGetSettings: () =>
			useQuery<IAppConfiguration>({
				queryKey: ['getSettings'],
				queryFn: () => {
					// console.log('useHygraph::useGetSettings');
					return new Promise((resolve, reject) => {
						// eslint-disable-next-line @typescript-eslint/no-unsafe-call
						request<ISettingsResponse>(
							url,
							gql`
								query {
									general(where: { id: "cm2lilh6kitiv08lktz3a2gnn" }, stage: PUBLISHED) {
										cognitoClient
										cognitoUser
										id
										placeHolderImageUrl
										primaryColor {
											size100 {
												hex
											}
											size200 {
												hex
											}
											size300 {
												hex
											}
											size400 {
												hex
											}
											size50 {
												hex
											}
											size500 {
												hex
											}
											size600 {
												hex
											}
											size700 {
												hex
											}
											size800 {
												hex
											}
											size900 {
												hex
											}
										}
										placeHolderImageAsset {
											fileName
											handle
											url
											width
											size
											height
										}
										useLoginPage
										useImageUpload
										useCartPage
										primaryTextColor {
											size100 {
												hex
											}
											size200 {
												hex
											}
											size300 {
												hex
											}
											size400 {
												hex
											}
											size50 {
												hex
											}
											size500 {
												hex
											}
											size600 {
												hex
											}
											size700 {
												hex
											}
											size800 {
												hex
											}
											size900 {
												hex
											}
										}
										secondaryColor {
											size100 {
												hex
											}
											id
											size200 {
												hex
											}
											size300 {
												hex
											}
											size400 {
												hex
											}
											size50 {
												hex
											}
											size500 {
												hex
											}
											size600 {
												hex
											}
											size700 {
												hex
											}
											size800 {
												hex
											}
											size900 {
												hex
											}
										}
										secondaryTextColor {
											size100 {
												hex
											}
											size200 {
												hex
											}
											size300 {
												hex
											}
											size400 {
												hex
											}
											size50 {
												hex
											}
											size500 {
												hex
											}
											size600 {
												hex
											}
											size700 {
												hex
											}
											size800 {
												hex
											}
											size900 {
												hex
											}
										}
									}
								}
							`,
							undefined,
							{ Authorization: `Bearer ${accessToken}` },
						)
							// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
							.then((d) => {
								// console.log(d);
								const appConfig: IAppConfiguration = {
									cognitoClientId: d.general.cognitoClient,
									cognitoUserPoolId: d.general.cognitoUser,
									// TODO Determine if we're using imageAsset or not.
									placeHolderImage: d.general.placeHolderImageUrl,
									useCartPage: d.general.useCartPage,
									useLoginPage: d.general.useLoginPage,
									theme: {
										fonts: {
											heading: `'Open Sans', sans-serif`,
											body: `'Open Sans', sans-serif`,
											mono: `'Open Sans', sans-serif`,
										},
										colors: {
											primary: {
												'50': d.general.primaryColor.size50.hex,
												'100': d.general.primaryColor.size100.hex,
												'200': d.general.primaryColor.size200.hex,
												'300': d.general.primaryColor.size300.hex,
												'400': d.general.primaryColor.size400.hex,
												'500': d.general.primaryColor.size500.hex,
												'600': d.general.primaryColor.size600.hex,
												'700': d.general.primaryColor.size700.hex,
												'800': d.general.primaryColor.size800.hex,
												'900': d.general.primaryColor.size900.hex,
											},
											primaryText: {
												'50': d.general.primaryTextColor.size50.hex,
												'100': d.general.primaryTextColor.size100.hex,
												'200': d.general.primaryTextColor.size200.hex,
												'300': d.general.primaryTextColor.size300.hex,
												'400': d.general.primaryTextColor.size400.hex,
												'500': d.general.primaryTextColor.size500.hex,
												'600': d.general.primaryTextColor.size600.hex,
												'700': d.general.primaryTextColor.size700.hex,
												'800': d.general.primaryTextColor.size800.hex,
												'900': d.general.primaryTextColor.size900.hex,
											},
											secondary: {
												'50': d.general.secondaryColor.size50.hex,
												'100': d.general.secondaryColor.size100.hex,
												'200': d.general.secondaryColor.size200.hex,
												'300': d.general.secondaryColor.size300.hex,
												'400': d.general.secondaryColor.size400.hex,
												'500': d.general.secondaryColor.size500.hex,
												'600': d.general.secondaryColor.size600.hex,
												'700': d.general.secondaryColor.size700.hex,
												'800': d.general.secondaryColor.size800.hex,
												'900': d.general.secondaryColor.size900.hex,
											},
											secondaryText: {
												'50': d.general.secondaryTextColor.size50.hex,
												'100': d.general.secondaryColor.size100.hex,
												'200': d.general.secondaryColor.size200.hex,
												'300': d.general.secondaryColor.size300.hex,
												'400': d.general.secondaryColor.size400.hex,
												'500': d.general.secondaryColor.size500.hex,
												'600': d.general.secondaryColor.size600.hex,
												'700': d.general.secondaryColor.size700.hex,
												'800': d.general.secondaryColor.size800.hex,
												'900': d.general.secondaryColor.size900.hex,
											},
										},
									},
								};
								resolve(appConfig);
							})
							// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
							.catch((e: unknown) => {
								console.error(e);
								reject(e);
							});
					});
				},
			}),
	};
};
