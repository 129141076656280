import { useContext, useEffect } from 'react';
import { AppContext } from '../../../data/globalstate';
import { useApiManager } from '../../../domain/hooks';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
	VStack,
	Spinner,
	Heading,
	HStack,
	Radio,
	RadioGroup,
	Stack,
	FormControl,
	FormLabel,
	Button,
	Text,
	Progress,
} from '@chakra-ui/react';
import React from 'react';
import { EPromotionType, IClaimPromotionRequest } from '../../../domain/models';
import { useQueryClient } from '@tanstack/react-query';

// TODO Refactor
interface IEarningAction {
	id: string;
	type: EPromotionType;
	action?: string;
	points: number;
	title: string;
	description: string;
	actionText?: string;
}

interface IProps {
	showModal: boolean;
	setShowModal: React.Dispatch<boolean>;
	earningAction?: IEarningAction;
	setSelectedAction: React.Dispatch<IEarningAction | undefined>;
}

// TODO Form Validation

export const PollModal: React.FC<IProps> = ({
	showModal,
	setShowModal,
	earningAction,
	setSelectedAction,
}) => {
	const { user } = useContext(AppContext);
	const { useClaimEarningAction } = useApiManager();
	const { mutateAsync, error, isSuccess, isPending, reset } = useClaimEarningAction();
	const qc = useQueryClient();
	// TODO Get Poll Details
	const [value, setValue] = React.useState('1');

	//Reset Video Watched tracking.
	useEffect(() => {
		if (!showModal) {
			reset();
			setSelectedAction(undefined);
		}
		// eslint-disable-next-line
	}, [showModal]);

	return (
		<Modal
			size={'xl'}
			isOpen={showModal}
			onClose={() => {
				// KEEP OPEN
				// setShowModal(false);
			}}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader textAlign={'center'} textTransform={'uppercase'}>
					{earningAction?.title}
				</ModalHeader>
				<ModalCloseButton
					onClick={() => {
						setShowModal(false);
					}}
				/>
				<ModalBody>
					{error && (
						<Alert
							status="error"
							flexDirection="column"
							alignItems="center"
							justifyContent="center"
							textAlign="center"
						>
							<AlertIcon />
							<AlertTitle>We encountered an error awarding your points.</AlertTitle>
							<AlertDescription>{error.message}</AlertDescription>
						</Alert>
					)}
					{isSuccess && (
						<VStack>
							<Alert
								status="success"
								flexDirection="column"
								alignItems="center"
								justifyContent="center"
								textAlign="center"
							>
								<AlertIcon />
								<AlertTitle>You've earned {earningAction?.points} points!</AlertTitle>
								<AlertDescription>You may now close this window.</AlertDescription>
							</Alert>
							<Text>Completed on {new Date().toLocaleDateString()}</Text>
							<HStack alignSelf={'stretch'}>
								<Progress
									value={50}
									flex={1}
									colorScheme="primary"
									size={'lg'}
									backgroundColor={'secondary.500'}
								>
									<Text position={'absolute'} top={0} color={'primaryText.500'} fontSize={'small'}>
										Mountains
									</Text>
								</Progress>
								<Text>50%</Text>
							</HStack>
							<HStack alignSelf={'stretch'}>
								<Progress
									value={30}
									flex={1}
									colorScheme="primary"
									size={'lg'}
									backgroundColor={'secondary.500'}
								>
									<Text position={'absolute'} top={0} color={'primaryText.500'} fontSize={'small'}>
										Beaches
									</Text>
								</Progress>
								<Text>30%</Text>
							</HStack>
							<HStack alignSelf={'stretch'}>
								<Progress
									value={10}
									flex={1}
									colorScheme="primary"
									size={'lg'}
									backgroundColor={'secondary.500'}
								>
									<Text position={'absolute'} top={0} color={'primaryText.500'} fontSize={'small'}>
										Forests
									</Text>
								</Progress>
								<Text>10%</Text>
							</HStack>
							<HStack alignSelf={'stretch'}>
								<Progress
									value={0}
									flex={1}
									colorScheme="primary"
									size={'lg'}
									backgroundColor={'secondary.500'}
								>
									<Text position={'absolute'} top={0} color={'primaryText.500'} fontSize={'small'}>
										Deserts
									</Text>
								</Progress>
								<Text>0%</Text>
							</HStack>
						</VStack>
					)}
					{isPending && (
						<VStack>
							<Spinner size="xl" color={'primary.500'} />
							<Heading>Claiming Points...</Heading>
						</VStack>
					)}
					{!isPending && !isSuccess && (
						<VStack>
							<FormControl>
								<FormLabel>Which natural landscape do you enjoy the most?</FormLabel>
								<RadioGroup onChange={setValue} value={value}>
									<Stack direction="column">
										<Radio value="1">Mountains</Radio>
										<Radio value="2">Beaches</Radio>
										<Radio value="3">Forests</Radio>
										<Radio value="4">Deserts</Radio>
									</Stack>
								</RadioGroup>
							</FormControl>
							<Button
								colorScheme="primary"
								variant={'outline'}
								onClick={() => {
									const ea: IClaimPromotionRequest = {
										transactionDescription: earningAction?.title ?? 'Unknown Earning Action',
										membershipCode: user?.burstId ?? null,
										activityType: EPromotionType.Poll,
										activityKey: earningAction?.id ?? '',
										partnerCode: 'PG',
										transactionProperties: null,
										itemProperties: null,
									};
									// console.log('Claim Action', ea);
									mutateAsync(ea)
										.then(() => {
											// console.log('New Transaction Success :: ', v);
											void qc.invalidateQueries({ queryKey: ['GetAction', earningAction?.id] });
										})
										.catch((e: unknown) => {
											console.error(e);
											// TODO
										});
								}}
							>
								Submit
							</Button>
						</VStack>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
