import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Heading,
	VStack,
	Skeleton,
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../data/globalstate';
import { useApiManager } from '../../../domain/hooks';
import { Pagination } from '../pager/Pagination';
import { IGetUserHistoryRequestParameters } from '../../../domain/models';

const AccountHistoryRedemption: React.FC = () => {
	const [currentPage, setCurrentPage] = useState<number>(1);
	const { user } = useContext(AppContext);
	const [getParams, setGetParams] = useState<IGetUserHistoryRequestParameters>({
		UserId: user?.burstId ?? '',
		// PartnerCode: 'PG',
		// IsActive: true,
		PageSize: 20,
		Page: currentPage,
		// SortBy: 'CreatedOn',
		// SortDirection: 'DESC',
	});
	const { useGetRedemptionHistory } = useApiManager();
	const { data, isLoading, error } = useGetRedemptionHistory(getParams);
	const noTransactions = !data?.transactions || data.transactions.length <= 0;

	//On Page Changed
	useEffect(() => {
		setGetParams({
			...getParams,
			Page: currentPage,
		});
		// eslint-disable-next-line
	}, [currentPage]);

	return (
		<VStack
			alignSelf={'stretch'}
			mt={'1rem'}
			backgroundColor={'white'}
			color={'secondary.500'}
			boxShadow={'0px 3px 6px #00000029'}
			alignItems={'flex-start'}
		>
			<Heading
				as="h2"
				textTransform="uppercase"
				backgroundColor={'primary.500'}
				color={'primaryText.500'}
				p="10px"
				alignSelf={'stretch'}
				fontSize={'x-large'}
			>
				redemption ACTIVITY
			</Heading>
			{error && (
				<Alert status="error">
					<AlertIcon />
					<AlertTitle>Error getting your redemption activities.</AlertTitle>
					<AlertDescription>{error.message}</AlertDescription>
				</Alert>
			)}
			<Skeleton isLoaded={!isLoading} w={'100%'}>
				<TableContainer width="100%">
					<Table variant="striped">
						<Thead>
							<Tr>
								<Th>DATE</Th>
								<Th>POINTS</Th>
								<Th>NAME</Th>
								<Th>CONFIRMATION NUMBER</Th>
								<Th>STATUS</Th>
							</Tr>
						</Thead>
						<Tbody>
							{noTransactions && (
								<Tr>
									<Td colSpan={5}>
										<Heading as={'h3'} textAlign={'center'}>
											No Redemption Activity
										</Heading>
									</Td>
								</Tr>
							)}
							{data?.transactions?.map((v, i) => {
								// console.log(v);

								let name = 'Unknown Redemption';
								if (v.rewardName) {
									name = v.rewardName;
								}
								return (
									<Tr key={`history_${i.toString()}`}>
										<Td>{new Date(v.transactionDate).toLocaleDateString('en-US')}</Td>
										<Td>{v.totalPoints} PTS</Td>
										<Td>{name}</Td>
										<Td>{v.transactionId}</Td>
										<Td>Completed</Td>
									</Tr>
								);
							})}
						</Tbody>
					</Table>
				</TableContainer>
				<Pagination
					// siblingCount={2}
					isLoading={isLoading}
					props={{
						count: data?.pageInfo?.count ?? 0,
						pageSize: data?.pageInfo?.pageSize,
						page: currentPage,
						onPageChange: (e) => {
							// console.log('On Page Change::', e);
							setCurrentPage(e.page);
						},
					}}
				/>
			</Skeleton>
		</VStack>
	);
};

export default AccountHistoryRedemption;
