import { VStack, Skeleton, HStack, Progress, Text } from '@chakra-ui/react';
import { FaCircle, FaRegCircle } from 'react-icons/fa';
import { useApiManager } from '../../../domain/hooks';
import { useContext } from 'react';
import { AppContext } from '../../../data/globalstate';
import { IGetMemberBalanceResponse, ILoginResponse, ITierDto } from '../../../domain/models';
import { maxBy } from 'lodash';

interface ITierTracker {
	balanceLoading: boolean;
	balanceData: IGetMemberBalanceResponse | undefined;
}

const lastTierPoints = (tiers: ITierDto[] | null | undefined) => {
	let p: number | null | undefined = undefined;

	if (tiers && tiers.length > 0) {
		const maxT = maxBy(tiers, 'from');
		// console.log(maxT);
		p = maxT?.from;
	}

	return p;
};

const pointsToNextTier = (
	membershipData: ILoginResponse | undefined,
	balanceData: IGetMemberBalanceResponse | undefined,
	tiers: ITierDto[] | null | undefined,
) => {
	let p = 0;
	// console.log('pointsToNextTier::', balanceData?.balance.balance);
	if (membershipData?.member && balanceData?.balance && tiers && tiers.length > 0) {
		const currTier = tiers.find(
			(t) =>
				t.to !== null &&
				t.from !== null &&
				t.to > balanceData.balance.balance &&
				t.from < balanceData.balance.balance,
		);
		// console.log(currTier);
		// console.log('pointsToNextTier::', currTier?.to);
		p = (currTier?.to ?? 0) - balanceData.balance.balance;
		// console.log('pointsToNextTier::', (currTier?.to ?? 0) - balanceData.balance.balance);
		// NOTE - Test for Negative.
		if (p < 0) {
			p = 0;
		}
		// NOTE - Check for max reached
		const max = lastTierPoints(tiers);
		if (max && balanceData.balance.balance > max) {
			p = 0;
		}
	}
	return p;
};

export const TierTracker: React.FC<ITierTracker> = ({ balanceLoading, balanceData }) => {
	const { user, tiers } = useContext(AppContext);
	const { useGetMembership } = useApiManager();
	const { data: membershipData, isLoading: membershipLoading } = useGetMembership({
		membershipCode: user?.burstId ?? '',
	});
	const pointsNeeded = pointsToNextTier(membershipData, balanceData, tiers);
	// console.log(balanceData?.balance.membershipLifeTimeValue);
	// console.log(lastTierPoints(tiers));
	// console.log((balanceData?.balance.membershipLifeTimeValue ?? 1) / (lastTierPoints(tiers) ?? 1));
	return (
		<VStack gap={0} textColor={'secondary.500'} my={5}>
			<Skeleton isLoaded={!membershipLoading}>
				<Text textTransform={'uppercase'} fontSize={'md'}>
					Status: {membershipData?.member.tier}
				</Text>
			</Skeleton>
			<HStack>
				<Text textTransform={'uppercase'} fontSize={'md'}>
					points to next tier:
				</Text>
				<Skeleton isLoaded={!membershipLoading}>
					<Text textTransform={'uppercase'} fontWeight={'bold'} fontSize={'md'}>
						{pointsNeeded.toLocaleString('en-US')}
					</Text>
				</Skeleton>
			</HStack>
			<HStack
				minW={360}
				position={'relative'}
				justifyContent={'space-between'}
				mr={'1rem'}
				minH={'80px'}
			>
				<Skeleton isLoaded={!balanceLoading} position={'absolute'} left={0} zIndex={0} w={'100%'}>
					<Progress
						value={
							((balanceData?.balance.membershipLifeTimeValue ?? 1) / (lastTierPoints(tiers) ?? 1)) *
							100
						}
						// value={0} //50 Crest, 20 Ridge, 100 Summit, 0 Base
						size={'md'}
						colorScheme="secondary"
						borderRadius={'50vh'}
					/>
				</Skeleton>
				{tiers?.map((t, idx) => {
					// console.log(idx);
					// console.log(t);
					// console.log(t.from / lastTierPoints(tiers));
					const maxPoints = lastTierPoints(tiers);

					let calcXPos = 0;
					if (maxPoints && t.from) {
						// console.log(t.from / maxPoints);
						calcXPos = t.from / maxPoints;
					}
					// console.log(calcXPos);

					return (
						<VStack
							key={`tier_${idx.toString()}`}
							zIndex={1}
							pos={'absolute'}
							left={`${(calcXPos * 100).toString()}%`}
							transform={'translateX(-50%)'}
							// transform={
							// 	idx === 0
							// 		? 'translateX(-35%)'
							// 		: idx === tiers.length - 1
							// 			? 'translateX(35%)'
							// 			: `translateX(${(calcXPos * 100).toString()}%)`
							// }
						>
							<Text textTransform={'uppercase'} fontSize={'sm'}>
								{t.tierName}
							</Text>
							{balanceData?.balance.membershipLifeTimeValue &&
								balanceData.balance.membershipLifeTimeValue >= (t.from ?? 0) && <FaCircle />}
							{!balanceData?.balance.membershipLifeTimeValue ||
								(balanceData.balance.membershipLifeTimeValue < (t.from ?? 0) && <FaRegCircle />)}
							<Text textTransform={'uppercase'} fontSize={'sm'}>
								{t.from?.toLocaleString('en-US')}
							</Text>
						</VStack>
					);
				})}
			</HStack>
		</VStack>
	);
};
