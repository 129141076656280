import { Badge, Box, Flex, IconButton } from '@chakra-ui/react';
import { FaShoppingCart } from 'react-icons/fa';
// import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { useContext } from 'react';
import CartDrawer from './CartDrawer';
import { AppContext } from '../../../data/globalstate';
import { NavLink } from 'react-router-dom';
import { useCartManager } from '../../../domain/hooks';

const CartBar: React.FC = () => {
	const { setShowCart, showCart, appConfiguration } = useContext(AppContext);
	const { useGetCart } = useCartManager();
	const { data: cartData } = useGetCart();

	let numItems = 0;
	cartData?.items.forEach((v) => {
		numItems += v.quantity;
	});

	return (
		<Flex
			bg={'primary.500'}
			w="100%"
			minH={'48px'}
			px={'20px'}
			py={'10px'}
			justifyContent={'flex-end'}
		>
			<Box pos={'relative'}>
				{appConfiguration?.useCartPage && (
					<IconButton
						as={NavLink}
						aria-label="Shopping Cart"
						icon={<FaShoppingCart />}
						color={'white'}
						variant={'ghost'}
						to={'/cart'}
					/>
				)}
				{!appConfiguration?.useCartPage && (
					<IconButton
						aria-label="Shopping Cart"
						icon={<FaShoppingCart />}
						color={'white'}
						variant={'ghost'}
						onClick={() => {
							// Show the Cart Drawer
							setShowCart(!showCart);
						}}
					/>
				)}
				{numItems > 0 && (
					<Badge pos={'absolute'} borderRadius={'50%'} top={0} transform={'translate(-50%,0)'}>
						{numItems}
					</Badge>
				)}
			</Box>
			{/* <ColorModeSwitcher justifySelf="flex-end" /> */}

			<CartDrawer />
		</Flex>
	);
};
export default CartBar;
