import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
	Text,
	ToastId,
	useDisclosure,
	useToast,
	VStack,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { AppContext } from '../../../data/globalstate';
import { NavLink, useNavigate } from 'react-router-dom';
import React from 'react';
import { useApiManager, useAuthManager } from '../../../domain/hooks';
import { AuthValidationScheme, IAuthForm } from './authModels';

const AuthModal: React.FC = () => {
	const { showAuth, setShowAuth, setUser } = useContext(AppContext);
	const { isOpen: showPassword, onToggle: onToggleShowPassword } = useDisclosure();
	const navigate = useNavigate();
	const toast = useToast();
	const toastIdRef = React.useRef<ToastId>();
	const [loading, setLoading] = useState<boolean>(false);
	const { useLogin: authLogin } = useAuthManager();
	const { useLogin: apiLogin } = useApiManager();
	const { mutateAsync: doAuthLogin } = authLogin();
	const { mutateAsync: doApiLogin } = apiLogin();

	const formik = useFormik<IAuthForm>({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: AuthValidationScheme,
		onSubmit: (values) => {
			if (values.email && values.password) {
				setLoading(true);
				toastIdRef.current = toast({
					title: 'Logging In...',
					status: 'loading',
					id: 'login',
					duration: null,
				});
				doAuthLogin({ userName: values.email, password: values.password })
					.then((res) => {
						// console.log('Login Successful');
						doApiLogin({ accountNumber: res.burstId })
							.then((v) => {
								// console.log(v);
								setUser({
									cognitoUser: res.cognitoUser,
									burstId: res.burstId,
									burstProfile: v,
								});

								if (toastIdRef.current) {
									toast.update(toastIdRef.current, {
										title: 'Login Successful!',
										status: 'success',
										isClosable: false,
										duration: 500,
									});
								}
								setLoading(false);
								setShowAuth(false);
								formik.resetForm();
							})
							.catch((e: unknown) => {
								if (toastIdRef.current) {
									toast.update(toastIdRef.current, {
										title: 'Profile Error',
										description: JSON.stringify(e),
										status: 'error',
										isClosable: true,
										duration: null,
									});
								}
								setLoading(false);
							});
					})
					.catch((e: unknown) => {
						console.error('Login Error');
						if (toastIdRef.current) {
							toast.update(toastIdRef.current, {
								title: 'Login Error',
								description: (e as Error).message || JSON.stringify(e),
								status: 'error',
								isClosable: true,
								duration: null,
							});
						}
						setLoading(false);
					});
			}
		},
	});
	// console.log(formik.errors);
	return (
		<Modal
			isOpen={showAuth}
			onClose={() => {
				formik.resetForm();
				setShowAuth(false);
			}}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader textAlign={'center'}>SIGN IN</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack spacing="8">
						<Stack spacing={{ base: '2', md: '3' }}>
							<Heading size={{ base: 'xs', md: 'sm' }}>Welcome back to Peak Rewards!</Heading>
							<Stack spacing="5">
								<FormControl isRequired isInvalid={Boolean(formik.errors.email)}>
									<FormLabel htmlFor="email">Email</FormLabel>
									<Input
										id="email"
										type="email"
										name="email"
										required
										value={formik.values.email}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
									{formik.errors.email && (
										<FormErrorMessage>{formik.errors.email}</FormErrorMessage>
									)}
								</FormControl>
								<FormControl isRequired isInvalid={Boolean(formik.errors.password)}>
									<FormLabel htmlFor="password">Password</FormLabel>
									<InputGroup>
										<InputRightElement>
											<IconButton
												variant="text"
												aria-label={showPassword ? 'Mask password' : 'Reveal password'}
												icon={showPassword ? <HiEyeOff /> : <HiEye />}
												onClick={onToggleShowPassword}
											/>
										</InputRightElement>
										<Input
											id="password"
											name="password"
											type={showPassword ? 'text' : 'password'}
											autoComplete="current-password"
											required
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
										/>
									</InputGroup>
									{formik.errors.password && (
										<FormErrorMessage>{formik.errors.password}</FormErrorMessage>
									)}
								</FormControl>
								<HStack justify="space-between" alignItems={'flex-start'}>
									<Link
										as={NavLink}
										to={'/forgot-password'}
										variant="text"
										size="sm"
										fontSize={'sm'}
									>
										Forgot password?
									</Link>
									<Button
										textTransform={'uppercase'}
										onClick={() => {
											toast.closeAll();
											formik.handleSubmit();
										}}
										isLoading={loading}
									>
										Sign in
									</Button>
								</HStack>
								<VStack justifyContent={'flex-start'} alignItems={'flex-start'}>
									<Text>DON&rsquo;T HAVE AN ACCOUNT?</Text>
									<HStack>
										<Link
											variant="text"
											onClick={() => {
												formik.resetForm();
												setShowAuth(false);
												navigate('/join');
											}}
											textDecoration={'underline'}
										>
											Register now
										</Link>
										<Text> for Peak Rewards today!</Text>
									</HStack>
								</VStack>
							</Stack>
						</Stack>
					</Stack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
export default AuthModal;
