// FIXME - We're gonna need to figure out how to do this once we have earning actions from burst.

import { Box, SimpleGrid } from '@chakra-ui/react';
import { EPromotionType, IEarningAction } from '../../../domain/models';
import { EarningActionCard } from './EarningActionCard';

const data: IEarningAction[] = [
	{
		id: 'PGPROM002249',
		type: EPromotionType.Purchase,
		action: 'https://www.amazon.com',
		points: 100,
		title: 'gear up',
		description: 'Earn 100 points for every $1 you spend at Pinnacle!',
		actionText: 'shop now',
	},
	{
		id: 'PGPROM002248',
		type: EPromotionType.AcceptedReferral,
		// action: 'https://www.amazon.com',
		points: 100,
		title: 'Share & Earn',
		description:
			'Share or post your unique link to Peak Rewards and get 100 points for every friend that joins. (Up to 10 friends.).',
	},
	{
		id: 'PGPROM002247',
		type: EPromotionType.Poll,
		// action: 'https://www.amazon.com',
		points: 10,
		title: 'take a poll',
		description: 'Answer a one-question poll and earn even more points!',
		actionText: 'ask away',
	},
	{
		id: 'PGPROM002244',
		type: EPromotionType.Profile,
		// action: 'https://www.amazon.com',
		points: 500,
		title: 'complete your profile',
		description: 'Tell us about yourself and earn rewards.',
		actionText: 'finish profile',
	},
	{
		id: 'PGPROM002245',
		type: EPromotionType.Video,
		action: 'https://www.youtube.com/watch?v=xvFZjo5PgG0',
		points: 20,
		title: 'view a gear demo',
		description: 'Checkout the latest & greatest products and earn 20 points.',
		actionText: 'watch now',
	},
	{
		id: 'PGPROM002282',
		type: EPromotionType.OptIn,
		// action: 'https://www.youtube.com/watch?v=xvFZjo5PgG0',
		points: 100,
		title: 'opt in to email',
		description: 'Earn rewards simply by opting in to receive emails from Pinnacle.',
		actionText: 'opt me in',
	},
	{
		id: 'PGPROM002246',
		type: EPromotionType.Anniversary,
		// action: 'https://www.youtube.com/watch?v=xvFZjo5PgG0',
		points: 10,
		title: 'peak rewards anniversary',
		description:
			'Earn 10 points just for logging in to Peak Rewards during your anniversary month!',
		// actionText: 'opt me in',
	},
	{
		id: 'PGPROM002253',
		type: EPromotionType.ProductReview,
		// action: 'https://www.youtube.com/watch?v=xvFZjo5PgG0',
		points: 75,
		title: 'leave a review',
		description: 'Tell others why you love Pinnacle products.',
		actionText: 'Review Now',
	},
];

interface IEarningGridProps {
	setSelectedAction: React.Dispatch<IEarningAction>;
	setShowVideoPlayer: React.Dispatch<boolean>;
	setShowPoll: React.Dispatch<boolean>;
	setShowProfileForm: React.Dispatch<boolean>;
	setShowReviewForm: React.Dispatch<boolean>;
}

export const EarningGrid: React.FC<IEarningGridProps> = ({
	setShowVideoPlayer,
	setSelectedAction,
	setShowPoll,
	setShowProfileForm,
	setShowReviewForm,
}) => {
	return (
		<Box
			maxW="7xl"
			mx="auto"
			px={{ base: '4', md: '8', lg: '12' }}
			py={{ base: '6', md: '8', lg: '12' }}
		>
			<SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} gap={{ base: '6', lg: '4' }}>
				{data.map((item, idx) => (
					<EarningActionCard
						key={`ea_${idx.toString()}`}
						item={item}
						setSelectedAction={setSelectedAction}
						setShowVideoPlayer={setShowVideoPlayer}
						setShowPoll={setShowPoll}
						setShowProfileForm={setShowProfileForm}
						setShowReviewForm={setShowReviewForm}
					/>
				))}
			</SimpleGrid>
		</Box>
	);
};
